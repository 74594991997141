import { db } from '@/utils/firebase.js';

import dynStorage from '@/utils/storage-dynamic';

import { firestoreAction } from 'vuexfire';

import i18n from '@/i18n';

const POTRING_STATES = [
    // 1 = PORT_REQUESTED
    {
        status: i18n.t('POTRING_STATES.PORT_REQUESTED.status'),
        tooltip: i18n.t('POTRING_STATES.PORT_REQUESTED.tooltip'),
    },
    // 2 = PORT_VALIDATED
    {
        status: i18n.t('POTRING_STATES.PORT_VALIDATED.status'),
        tooltip: i18n.t('POTRING_STATES.PORT_VALIDATED.tooltip'),
    },
    // 3 = PORT_APPROVED
    {
        status: i18n.t('POTRING_STATES.PORT_APPROVED.status'),
        tooltip: i18n.t('POTRING_STATES.PORT_APPROVED.tooltip'),
    },
    // 4 = INTRA_CARRIER_WAIT
    {
        status: i18n.t('POTRING_STATES.INTRA_CARRIER_WAIT.status'),
        tooltip: i18n.t('POTRING_STATES.INTRA_CARRIER_WAIT.tooltip'),
    },
    // 5 = NETWORK_PROVISIONING
    {
        status: i18n.t('POTRING_STATES.NETWORK_PROVISIONING.status'),
        tooltip: i18n.t('POTRING_STATES.NETWORK_PROVISIONING.tooltip'),
    },
    // 6 = THIRD_PARTY_NETWORKS
    {
        status: '',
        tooltip: ''
    },
    // 7 = PORT_COMPLETED
    {
        status: i18n.t('POTRING_STATES.PORT_COMPLETED.status'),
        tooltip: i18n.t('POTRING_STATES.PORT_COMPLETED.tooltip'),
    },
    // 8 = PORT_CANCELED
    {
        status: i18n.t('POTRING_STATES.PORT_CANCELED.status'),
        tooltip: i18n.t('POTRING_STATES.PORT_CANCELED.tooltip'),
    },
    // 9 = PORT_REJECTED
    {
        status: i18n.t('POTRING_STATES.PORT_REJECTED.status'),
        tooltip: i18n.t('POTRING_STATES.PORT_REJECTED.tooltip'),
    },
    // 10 = SERVICE_ACTIVATION_COMPLETED
    {
        status: '',
        tooltip: '',
    },
    // 11 = SERVICE_ACTIVATION_FAILED
    {
        status: i18n.t('POTRING_STATES.SERVICE_ACTIVATION_FAILED.status'),
        tooltip: i18n.t('POTRING_STATES.SERVICE_ACTIVATION_FAILED.tooltip'),
    },
];

const usage_initial = [
    { CarryOverAmount: 0, AmountRemaining: 0, UsageRule: "", UsageType: "sms"       },
    { CarryOverAmount: 0, AmountRemaining: 0, UsageRule: "", UsageType: "mms"       },
    { CarryOverAmount: 0, AmountRemaining: 0, UsageRule: "", UsageType: "voice"     },
    { CarryOverAmount: 0, AmountRemaining: 0, UsageRule: "", UsageType: "data"      },
    { CarryOverAmount: 0, AmountRemaining: 0, UsageRule: "", UsageType: "idd_sms"   },
    { CarryOverAmount: 0, AmountRemaining: 0, UsageRule: "", UsageType: "idd_voice" },
];

const CHANGE_PLAN_MODE = {
    NOW: 'NOW',
    LATER: 'LATER',
    BOTH: 'BOTH',
}

export default {
  state: {
	//   _old_
	// is_loading_customer_data: false,
	// customer_products: [],
	// customer_prepayed_products: [],
	// customer_postpay_products: [],
	// customer_mobile_products: [],

	customer_mobiles: [],

    is_customer_all_products_binded: false,

    customer_current_product: null,
    customer_current_mobile_product_uuid: '',
    // customer_current_prepayed_product_balance: 0,
    customer_current_product_get_usage_trigger: null,
    is_getting_current_product_usage: false,
    customer_current_product_usage: usage_initial,
    customer_current_product_usage_error: null,
    customer_current_product_usage_has_data: false,

	customer_future_postpay_plan: {},

    is_customer_current_addons_payg: false,
    is_on_transfer_product_type: false,
  },

  getters: {
    current_plan: (state, getters) =>
      (getters.current_account && getters.current_account.info?.currentPlan && getters.getPlanById(getters.current_account.info?.currentPlan.id)) ||
      null,

    current_plan_expire: (state, getters) =>
      getters.current_account && getters.current_account.info?.currentPlan && getters.current_account.info?.currentPlan.dateExpire,

    current_plan_expire_date: (state, getters) =>
      (getters.current_plan_expire && !isNaN(new Date(getters.current_plan_expire)) && new Date(getters.current_plan_expire)) || null,

	// customer_products: (state) => state.customer_products,
	// customer_addons: (state) => state.customer_products.filter(product => product.ProductType === 'prepaymobileAddon'),
    
    is_customer_all_products_binded: (state) => state.is_customer_all_products_binded,
    // customer_mobile_products: (state) => state.customer_mobile_products,

    is_on_transfer_product_type: (state) => state.is_on_transfer_product_type,

    customer_mobiles: (state) => state.customer_mobiles,
    
	// has_multiple_mobile_products: (state) => state.customer_mobile_products.length > 1,
	has_multiple_mobile_products: (state) => state.customer_mobiles.length > 1,

    /* customer_not_activated_mobile_products: (state, getters) => getters.customer_mobile_products.filter(
        mobile_product => mobile_product.ChildProductInstanceUUID == '' && mobile_product.ServiceType == 0).map(product => ({...product, ServiceType: 'Not Activated'})
    ), */

    customer_not_activated_mobile_products: (state, getters) => getters.customer_mobiles.filter(
        mobile_product => mobile_product.Status < 4 || !mobile_product.BillingReference).map(product => ({...product, ServiceType: 'Not Activated'})
    ),

    customer_not_active_mobile_products: (state, getters) => getters.customer_mobiles.filter(
        mobile_product => mobile_product.Status > 4).map(product => ({...product, ServiceType: 'Not Active'})
    ),
	
	/* customer_prepayed_products: (state, getters) => state.customer_prepayed_products.filter(
        prepayed_product => getters.customer_mobile_products.find(mobile_product => mobile_product.ChildProductInstanceUUID == prepayed_product.UUID && mobile_product.ServiceType == 1)
    ).map(product => ({...product, ServiceType: 'prepay'})), */

	customer_prepayed_products: (state, getters) => state.customer_mobiles.filter(
        mobile_product => mobile_product.Status == 4 && (mobile_product.BillingReference?.startsWith('prepay') || mobile_product.BillingReference?.startsWith('payg'))
    ).map(product => ({...product, ServiceType: 'prepay'})),

	/* customer_postpay_products: (state, getters) => state.customer_postpay_products.filter(
        postpay_product => getters.customer_mobile_products.find(mobile_product => mobile_product.ChildProductInstanceUUID == postpay_product.UUID && mobile_product.ServiceType == 2)
    ).map(product => ({...product, ServiceType: 'postpay'})), */

	customer_postpay_products: (state, getters) => state.customer_mobiles.filter(
        mobile_product => mobile_product.Status == 4 && mobile_product.BillingReference?.startsWith('postpay')
    ).map(product => ({...product, ServiceType: 'postpay'})),
	
	customer_all_products: (state, getters) => [
        ...getters.customer_prepayed_products,
        ...getters.customer_postpay_products,
        ...getters.customer_not_activated_mobile_products,
        ...getters.customer_not_active_mobile_products
    ],
	
    customer_current_product: (state) => state.customer_current_product,
    has_customer_current_product: (state) => Boolean(state.customer_current_product),
    
    customer_current_product_has_addons: (state, getters) => getters.customer_current_plan?.Addons?.length > 0,
    customer_current_product_created_date_with_time: (state) => new Date(Math.round((state.customer_current_product?.CreatedAtNanos || 0) / 1000000)),
    customer_current_product_created_date: (state, getters) => new Date(getters.customer_current_product_created_date_with_time.toDateString()),
	customer_current_product_uuid: (state) => state.customer_current_product?.UUID,
	customer_current_product_imsi: (state) => state.customer_current_product?.IMSI,
	customer_current_product_simid: (state) => state.customer_current_product?.SIMID,
	// customer_current_product_state: (state) => state.customer_current_product?.State,
	customer_current_product_status: (state) => state.customer_current_product?.Status,

	customer_current_product_phone: (state) => state.customer_current_product?.MSISDN ? `+${state.customer_current_product.MSISDN}` : '',
    customer_current_mobile_product_uuid: (state) => state.customer_current_mobile_product_uuid,
    customer_current_mobile_product: (state, getters) => getters.customer_all_products.find(mobile_product => mobile_product.UUID == getters.customer_current_mobile_product_uuid),
    customer_current_mobile_product_billing_reference: (state, getters) => getters.customer_current_mobile_product?.BillingReference || '',
    curr_billing_reference_type: (state, getters) => {
        const [curr_billing_reference_type] = getters.customer_current_mobile_product_billing_reference.split(':')

        return curr_billing_reference_type
    },
	
    accountType: (state, getters) => getters.customer_current_product?.ServiceType || 'Not Activated',
    is_account_type_activated: (state, getters) => getters.is_account && getters.accountType && getters.accountType != 'Not Activated',

	customer_current_postpay_plan: (state, getters) => getters.customer_postpay_products.find(postpay_product => postpay_product.UUID == getters.customer_current_product_uuid),
    customer_current_prepayed_plan: (state, getters) => getters.customer_prepayed_products.find(prepayed_product => prepayed_product.UUID == getters.customer_current_product_uuid),
    customer_current_plan: (state, getters) => getters.is_account_postpay ? getters.customer_current_postpay_plan : getters.customer_current_prepayed_plan,
    customer_current_plan_porting_state: (state, getters) => getters.customer_current_plan?.PortingState || 0,
    customer_current_plan_porting_tooltip_message: (state, getters) => {
        const porting_state = getters.customer_current_plan_porting_state

        if (!porting_state) return ''

        const tooltip_title = POTRING_STATES[porting_state -1]?.status

        if (!tooltip_title) return ''

        const tootltip_subtitle = POTRING_STATES[porting_state -1]?.tooltip

        if (!tootltip_subtitle) return ''

        return `<b>${tooltip_title}</b><br/>${tootltip_subtitle}`
    },

	customer_future_postpay_plan: state => state.customer_future_postpay_plan?.ProductCatalogUUID ? state.customer_future_postpay_plan : null, // ?

    customer_current_product_catalog_uuid: (state, getters) => getters.customer_current_plan?.ProductCatalogUUID, // ?
    
    customer_current_product_get_usage_trigger: (state) => state.customer_current_product_get_usage_trigger,
    is_getting_current_product_usage: (state) => state.is_getting_current_product_usage,
    customer_current_product_usage: (state) => state.customer_current_product_usage,
    customer_current_product_usage_has_data: (state) => state.customer_current_product_usage_has_data,
    customer_current_product_usage_error: (state) => state.customer_current_product_usage_error,
	
	// customer_current_prepayed_plan_balance: (state, getters) => state.customer_current_prepayed_product_balance || 0,

    // customer_current_prepayed_addons: (state, getters) => getters.customer_current_prepayed_plan?.AddOns || [],
    customer_current_prepayed_addons: (state, getters) => getters.customer_current_prepayed_plan?.Addons?.sort((a, b) => b.CreatedAtNanos - a.CreatedAtNanos) || [],
    customer_current_postpay_addons: (state, getters) => getters.customer_current_postpay_plan?.Addons?.sort((a, b) => b.CreatedAtNanos - a.CreatedAtNanos) || [],
    customer_current_addons: (state, getters) => getters.is_account_postpay ?  getters.customer_current_postpay_addons : getters.customer_current_prepayed_addons,
    customer_current_recurring_addons: (state, getters) => getters.customer_current_addons.filter(addon => (addon.AllowAutoRenew === true || addon.AllowAutoRenew == 'true') && addon.EndAfterCycleCount < 1),

    is_show_prepay_change_plan_now_and_later: (state, getters) => getters.app_environment_prepay_change_plan_mode == CHANGE_PLAN_MODE.BOTH,
    is_app_prepay_change_plan_now: (state, getters) => getters.app_environment_prepay_change_plan_mode != CHANGE_PLAN_MODE.LATER,
    
    is_show_postpay_change_plan_now_and_later: (state, getters) => getters.app_environment_postpay_change_plan_mode == CHANGE_PLAN_MODE.BOTH,
    is_app_postpay_change_plan_now: (state, getters) => getters.app_environment_postpay_change_plan_mode != CHANGE_PLAN_MODE.LATER,

    app_environment_prepay_change_plan_mode: (state, getters) => {
       const mode = getters.getAppEnvParam('PREPAY_CHANGE_PLAN_MODE')

       return CHANGE_PLAN_MODE[mode] || 'NOW'
    },

    app_environment_postpay_change_plan_mode: (state, getters) => {
       const mode = getters.getAppEnvParam('POSTPAY_CHANGE_PLAN_MODE')

       return CHANGE_PLAN_MODE[mode] || 'NOW'
    },

    customer_current_payg_addons: (state, getters) => getters.customer_current_prepayed_addons.filter(addon => (addon.AllowAutoRenew === true || addon.AllowAutoRenew == 'true')), // ?
    // is_customer_current_addons_payg: (state) => state.is_customer_current_addons_payg, // ?
    is_customer_current_addons_payg: (state) => state.customer_current_product?.BillingReference?.startsWith('payg'),


    customer_current_postpay_totals: (state, getters) => {
        if (!getters.customer_current_postpay_plan) {
            return ({
                TotalDataMB: 0, 
                TotalMMS: 0,
                TotalSMS: 0,
                TotalVoiceMinutes: 0,
                TotalIDDSMS: 0,
                TotalIDDVoiceMinutes: 0,
            })
        }
        
        const {TotalDataMB, TotalMMS, TotalSMS, TotalVoiceMinutes, TotalIDDSMS, TotalIDDVoiceMinutes} = getters.customer_current_postpay_plan

        return {
            TotalDataMB,
            TotalMMS,
            TotalSMS, 
            TotalVoiceMinutes,
            TotalIDDSMS,
            TotalIDDVoiceMinutes,
        }
    },
    
	/* customer_current_prepayed_plan_cost: (state, getters) => getters.customer_current_prepayed_addons.reduce((acc, addon) => {
																	return acc + (addon.RecurringRetailPrice ?? 0) / 100;
																}, 0).toFixed(2), */



	// _old_
	// customer_current_plan: (state) => state.customer_products.filter(product => product.ProductType === 'prepaymobile')[0],
	// is_loading_customer_data: (state) => state.is_loading_customer_data,
  },

  actions: {
    getPrePurchasedPlan({ getters }, current_account_uid) {
        return db
            .collection(getters.collection_customers_name)
            .doc(current_account_uid)
            .collection('pre_purchased_plans')
            .get()
            .then(pre_purchased_plans => {
                const pre_purchased_plan = pre_purchased_plans.docs[0]?.data()

                return Promise.resolve(pre_purchased_plan)
            })
            .catch((error) => {
                console.error('Error getting PrePurchasedPlans:', error);
                return Promise.reject(error);
            });
    },

	/* BindPrepayedProducts: firestoreAction(({ bindFirestoreRef, getters, commit }, current_account_uid) => {
		return bindFirestoreRef('customer_prepayed_products', 
			db
			.collection(getters.collection_customers_name)
			.doc(current_account_uid)
			.collection(getters.collection_customers_prepay_products_name)
		).then(()=> {
            commit('SET_IS_CUSTOMER_CURRENT_ADDONS_PAYG', getters.customer_current_payg_addons.length > 0)
        })
	}), */
  
	/* UnbindPrepayedProducts: firestoreAction(({ unbindFirestoreRef }) => {
		return unbindFirestoreRef('customer_prepayed_products');
	}), */

    /* BindPostpayProducts: firestoreAction(({ bindFirestoreRef, getters }, current_account_uid) => {
		bindFirestoreRef('customer_future_postpay_plan',
			db
			.collection(getters.collection_customers_name)
			.doc(current_account_uid)
			.collection(getters.collection_customers_postpay_change_plan_name)
			.doc(getters.doc_customers_postpay_change_plan_name)
		)

		return bindFirestoreRef('customer_postpay_products', 
			db
			.collection(getters.collection_customers_name)
			.doc(current_account_uid)
			.collection(getters.collection_customers_postpay_products_name)
		)
	}), */
  
	/* UnbindPostpayProducts: firestoreAction(({ unbindFirestoreRef }) => {
		unbindFirestoreRef('customer_future_postpay_plan')

		return unbindFirestoreRef('customer_postpay_products');
	}), */

    BindCurrentProductGetUsageTrigger: firestoreAction(({ bindFirestoreRef, getters, dispatch }) => {
        const ref = db
            .collection(getters.collection_customers_name)
            .doc(getters.current_account_uid)
            .collection('product_usage')
            .doc(getters.customer_current_mobile_product_uuid);

        return bindFirestoreRef('customer_current_product_get_usage_trigger', ref).then(trigger => {
            if (!trigger) {
                getters.is_account_postpay ? dispatch('GetPostpayProductUsage') : dispatch('GetPrepayProductUsage');
            }
        }).catch(error => {
            console.error('BindCurrentProductGetUsageTrigger error', error)
            
            getters.is_account_postpay ? dispatch('GetPostpayProductUsage') : dispatch('GetPrepayProductUsage');
        });
	}),

    UnbindCurrentProductGetUsageTrigger: firestoreAction(({ unbindFirestoreRef, commit }) => {
        commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE_ERROR', null)
        commit('RESET_CUSTOMER_CURRENT_PRODUCT_USAGE')
        commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE_HAS_DATA', false)

		return unbindFirestoreRef('customer_current_product_get_usage_trigger');
	}),

    /* BindMobileProducts: firestoreAction(({ bindFirestoreRef, getters }, current_account_uid) => {
		return bindFirestoreRef('customer_mobile_products', 
			db
			.collection(getters.collection_customers_name)
			.doc(current_account_uid)
			.collection(getters.collection_customers_mobile_products_name)
		)
	}), */
  
	/* UnbindMobileProducts: firestoreAction(({ unbindFirestoreRef }) => {
		return unbindFirestoreRef('customer_mobile_products');
	}), */

    BindMobiles: firestoreAction(({ bindFirestoreRef, getters }, current_account_uid) => {
		return bindFirestoreRef('customer_mobiles', 
			db
			.collection(getters.collection_customers_name)
			.doc(current_account_uid)
			.collection(getters.collection_customers_mobiles_name)
		)
	}),
  
	UnbindMobiles: firestoreAction(({ unbindFirestoreRef }) => {
		return unbindFirestoreRef('customer_mobiles');
	}),

    BindCustomerAllProducts({ commit, dispatch }, current_account_uid) {
        return dispatch('BindMobiles', current_account_uid).then(() => {
            commit('SET_IS_CUSTOMER_ALL_PRODUCTS_BINDED', true)
        })

        /* return Promise.all([
            dispatch('BindPrepayedProducts', current_account_uid), 
            dispatch('BindPostpayProducts', current_account_uid), 
            dispatch('BindMobileProducts', current_account_uid)
        ]).then(() => {
            commit('SET_IS_CUSTOMER_ALL_PRODUCTS_BINDED', true)
        }) */
    },

    UnbindCustomerAllProducts({ commit, dispatch }) {
        return dispatch('UnbindMobiles').then(() => {
            commit('SET_IS_CUSTOMER_ALL_PRODUCTS_BINDED', false)
        })

        /* return Promise.all([
            dispatch('UnbindPrepayedProducts'),
            dispatch('UnbindPostpayProducts'),
            dispatch('UnbindMobileProducts'),
        ]).then(() => {
            commit('SET_IS_CUSTOMER_ALL_PRODUCTS_BINDED', false)
        }) */
    },

    AutoSelectMobileProduct({ getters, commit, dispatch }) {
        if (getters.is_app_environment_billing_routing_allowed) {
            dispatch('BindBillingRoutes', getters.current_account_uid)
        }
        
        return dispatch('BindCustomerAllProducts', getters.current_account_uid).then(() => {
            const customer_all_products = getters.customer_all_products

            if (customer_all_products.length == 0) { 
                commit('SET_CUSTOMER_CURRENT_PRODUCT', null)
                commit('SET_CUSTOMER_CURRENT_MOBILE_PRODUCT_UUID', '')

                return Promise.resolve(true) 
            }

            let customer_current_product = customer_all_products[0]

            if (customer_all_products.length > 1) { 
                for (let i = 0; i < customer_all_products.length; i++) {
                    if (customer_all_products[i].ServiceType != 'Not Activated') {
                        customer_current_product = customer_all_products[i]
    
                        break
                    }
                }
            }

            commit('SET_CUSTOMER_CURRENT_PRODUCT', customer_current_product)

            /* if (customer_current_product.ServiceType != 'Not Activated') {
                const customer_current_mobile_product = getters.customer_mobile_products.find(mobile_product => mobile_product.ChildProductInstanceUUID == getters.customer_current_product_uuid)

                if (customer_current_mobile_product) {
                    commit('SET_CUSTOMER_CURRENT_MOBILE_PRODUCT_UUID', customer_current_mobile_product.UUID)
                }
            } else {
                commit('SET_CUSTOMER_CURRENT_MOBILE_PRODUCT_UUID', getters.customer_current_product_uuid)
            } */

            commit('SET_CUSTOMER_CURRENT_MOBILE_PRODUCT_UUID', getters.customer_current_product_uuid)

            return Promise.resolve(customer_current_product) 
        }).catch(() => Promise.resolve(true))
    },

    SetCustomerCurrentProduct({ commit }, product) {
        commit('SET_CUSTOMER_CURRENT_PRODUCT', product)
    },

	/* BindFirebaseData({dispatch}) {
		dispatch('BindPrepayedProducts')
	}, */

    SetNewCurrentPlan({ dispatch, getters }, { plan, cost }) {
      const id = typeof plan == 'string' ? plan : plan.id;
      const new_plan = getters.getPlanById(id);

      if (!new_plan) {
        return Promise.reject(i18n.t('can_not_find_plan'));
      }

      if (getters.current_plan && id == getters.current_plan.id) {
        return Promise.reject(i18n.t('you_already_have_this_plan'));
      }

      const plan_cost = cost == undefined ? new_plan.cost : cost;

      const date = new Date();
      date.setMonth(date.getMonth() + 1);

      const dateExpire = date.toISOString();

      if (plan_cost === 0) {
        return dispatch('UpdateCurrentAccountInfo', { currentPlan: { id, dateExpire } });
      }
      return dispatch('ProcessBalanceWithdraw', plan_cost).then(() => {
        return dispatch('UpdateCurrentAccountInfo', { currentPlan: { id, dateExpire } });
      });
    },

    UseOfferInCurrentAccount({ dispatch }, offer) {
      const plan = offer.plan;
      const cost = offer.plan.cost * (offer.discount == null || isNaN(+offer.discount) ? 1 : 1 - +offer.discount);
      return dispatch('SetNewCurrentPlan', { plan, cost });
    },
  },

  mutations: {
    SET_IS_CUSTOMER_ALL_PRODUCTS_BINDED: (state, is_binded) => {
		state.is_customer_all_products_binded = is_binded;
	},

    SET_CUSTOMER_CURRENT_PRODUCT(state, product) {
		state.customer_current_product = product;

        this.commit('RESET_USAGE_CHARGES');

        if (product) {
            dynStorage.set('customer_current_product', product, null, true);
        } else {
            dynStorage.remove('customer_current_product');
        }
	},

    SET_CUSTOMER_CURRENT_MOBILE_PRODUCT_UUID: (state, mobile_product_uuid) => {
		state.customer_current_mobile_product_uuid = mobile_product_uuid;

        if (mobile_product_uuid) {
            dynStorage.set('customer_current_mobile_product_uuid', mobile_product_uuid, null, false);
        } else {
            dynStorage.remove('customer_current_mobile_product_uuid');
        }
	},

    SET_IS_GETTING_CUSTOMER_CURRENT_PRODUCT_USAGE(state, is_getting_current_product_usage) {
        state.is_getting_current_product_usage = is_getting_current_product_usage
    },

    SET_CUSTOMER_CURRENT_PRODUCT_USAGE: (state, usage) => {
		state.customer_current_product_usage = usage;
	},

    SET_CUSTOMER_CURRENT_PRODUCT_USAGE_HAS_DATA: (state, has_data) => {
		state.customer_current_product_usage_has_data = has_data;
	},

    RESET_CUSTOMER_CURRENT_PRODUCT_USAGE: (state) => {
		state.customer_current_product_usage = usage_initial;
	},

    SET_CUSTOMER_CURRENT_PRODUCT_USAGE_ERROR: (state, error) => {
		state.customer_current_product_usage_error = error;
	},

    SET_IS_CUSTOMER_CURRENT_ADDONS_PAYG: (state, is_customer_current_addons_payg) => {
		state.is_customer_current_addons_payg = is_customer_current_addons_payg;

        if (is_customer_current_addons_payg) {
            dynStorage.set('is_customer_current_addons_payg', is_customer_current_addons_payg, null, true);
        } else {
            dynStorage.remove('is_customer_current_addons_payg');
        }
	},

    SET_IS_ON_TRANSFER_PRODUCT_TYPE: (state, value) => {
		state.is_on_transfer_product_type = value;
	}, 

    /* SET_CUSTOMER_CURRENT_PREPAYED_PRODUCT_BALANCE: (state, value) => {
		state.customer_current_prepayed_product_balance = value;
	}, */

	//   _old
	/* SET_IS_LOADING_CUSTOMER_DATA: (state, value) => {
		state.is_loading_customer_data = value;
	},  */

	/* SET_CUSTOMER_PRODUCTS: (state, customer_products) => {
		state.customer_products = customer_products;
	}, */

	/* SET_CUSTOMER_PREPAY_PRODUCTS: (state, customer_prepay_products) => {
		state.customer_prepayed_products = customer_prepay_products;
	} */
  },
};
