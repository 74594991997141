<template>
    <div class="app-loader"
        :class="{
            'has-slots': has_slots,
            [fixed_class]: fixed_class,
        }"
    >
        <MountingPortal mountTo="#app-loader-layer" append>
            <div class="app-loader__layer"/>
        </MountingPortal>

        <div class="app-loader--content">
            <div class="app-loader--head">
                <slot name="head"></slot>
            </div>

            <app-animation v-if="animation_src && !animation_error" :src="animation_src" :stateMachines="animation_state_machines" @error="onAnimationError"/>
            <app-animation v-else-if="is_preloader_animated && !animation_error" :src="'/assets/animations/preloader.riv'" :stateMachines="AnimationsStateMachines.preloader" @error="onAnimationError"/>
            <app-svg v-else/>

            <div class="app-loader--desc">
                <slot name="desc"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appSvg from './components/app-svg';
import appAnimation from '@/components/app-animation';

const fixed_list = {
    desktop: true,
    tablet: true,
    mobile: true,
}

export default {
    components: {
        appSvg,
		appAnimation,
    },

    props: {
        fixedOn:                    { type: String,  default: '' }, // desktop | tablet | mobile
        animation_src:              { type: String,  default: '' },
        animation_state_machines:   { type: String               },
    },

    data() {
        return {
            animation_error: false,
        }
    },

    computed: {
        ...mapGetters(['getAppEnvParam', 'AnimationsStateMachines',]),

        has_slots() {
            return Boolean(this.$slots.head)
                || Boolean(this.$slots.desc)
        },

        fixed_class() {
            return this.fixedOn && this.fixedOn in fixed_list ? `fixed-on-${ this.fixedOn }` : false
        },

        is_preloader_animated() {
            return ['true', 'yes', 1].includes(this.getAppEnvParam('IS_PRELOADER_ANIMATED').toLowerCase())
        },
    },

    methods: {
        onAnimationError() {
            this.animation_error = true;
        },
    },
}
</script>

<style lang="scss">
@mixin fixed-on() {
    .app-loader {
        &--content {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: fixed;
        }
    }
}

.app-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $loader-bg-color;
    border-radius: inherit;
    margin: 0;
    padding: 20px;
    z-index: $z-index-loader;

    &--content {
        position: absolute;
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        z-index: $z-index-loader;
    }

    svg {
        content: "";
        width: 96px;
        height: 96px;
        max-width: 95%;
        max-height: 95%;
        object-fit: contain;

        circle {
            fill: $loader-color;
        }
    }

    &--head,
    &--desc {
        text-align: center;
        padding: 8px;
        backdrop-filter: blur(16px);
        background-color: rgba($white, .6);

        &:empty {
            display: none;
        }
    }

    &--head {
        color: $text-color-header;
        font-size: 32px;
        line-height: 40px;
        font-weight: bold;

        @include media-bp(mob) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &--desc {
        color: $text-color-base;
        font-size: 18px;
        line-height: 32px;

        @include media-bp(mob) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &.has-slots {
        .app-loader {
            &--content {
                width: 500px;
                height: 500px;
                max-width: 100%;
                max-height: 100%;
            }

            &--head,
            &--desc {
                position: absolute;
                left: 0;
                right: 0;
            }

            &--head { top: 0; }
            &--desc { bottom: 0; }

            @include media-bp(mob) {
                &--content {
                    width: 100%;
                    height: #{ 320px - (15px * 2) };
                }

                &--head {
                    transform: translateY(-50%)
                }

                &--desc {
                    transform: translateY(50%);
                }
            }
        }
    }

    &.fixed-on-desktop {
        @include fixed-on
    }

    &~* {
        .app-loader {
            display: none;
        }
    }
}
</style>