import axiosIntents from '@/utils/axios-intents'
import downloadBlob from '@/utils/downloadBlob.js'
import i18n from '@/i18n';

import StoreHash from '@/utils/StoreHash'

let cashed = {
    invoices_paginated: {
        pages: {},
        lifetime: 3 * 60 * 1000, // 3 minutes
    }
}

const INVOICE_STATUS = {
    OPEN: {
        TITLE: 'OPEN',
        COLOR: 'var(--invoices-open-color)',
    },
    PAID: {
        TITLE: 'PAID',
        COLOR: 'var(--invoices-paid-color)',
    },
    DUE: {
        TITLE: 'DUE',
        COLOR: 'var(--invoices-due-color)',
    },
    OVERDUE: {
        TITLE: 'OVERDUE',
        COLOR: 'var(--invoices-overdue-color)',
    },
    VOID: {
        TITLE: 'VOID',
        COLOR: 'var(--invoices-void-color)',
    },
}

const INVOICE_BILLING_CYCLES = {
    SEVEN_DAYS: {
        title: `7 ${i18n.t('days')}`,
        option: 'SEVEN_DAYS',
        type: 'daily',
        value: 7,
    },
    TWENTY_EIGHT_DAYS: {
        title: `28 ${i18n.t('days')}`,
        option: 'TWENTY_EIGHT_DAYS',
        type: 'daily',
        value: 28,
    },
    MONTHLY: {
        title: i18n.t('monthly_upper'),
        option: 'MONTHLY',
        type: 'monthly',
        value: 1,
    },
}

export default {
    state: {
        INVOICE_STATUS,
        INVOICE_BILLING_CYCLES,
        invoices: [],
        is_loading_invoices: false,
        is_downloading_invoice_pdf: false,
        invoices_loading_error: false,
        invoices_current_billing_cycle: null,

        invoices_paginated: Array(),
        loading_invoices_paginated: Array(),
        invoices_loading_error_pagination: Array(),
        invoices_paginated_limit: 8,
        invoices_paginated_total: 0,
    },

    getters: {
        invoices: state =>  state.invoices,
        current_period_invoice: (state, getters) => getters.invoices.find(invoice => invoice.Status.toUpperCase() == INVOICE_STATUS.OPEN.TITLE),
        current_period_invoices: (state, getters) => getters.invoices.filter(invoice => invoice.Status.toUpperCase() == INVOICE_STATUS.OPEN.TITLE),
        previous_period_invoices: (state, getters) => state.is_loading_invoices ? [] : getters.invoices.filter(invoice => invoice.Status.toUpperCase() != INVOICE_STATUS.OPEN.TITLE),
        has_overdue_invoice: (state, getters) => getters.overdue_invoices.length > 0,
        due_invoices: (state, getters) => state.is_loading_invoices ? [] : getters.invoices.filter(invoice => invoice.Status.toUpperCase() == INVOICE_STATUS.DUE.TITLE),
        overdue_invoices: (state, getters) => state.is_loading_invoices ? [] : getters.invoices.filter(invoice => invoice.Status.toUpperCase() == INVOICE_STATUS.OVERDUE.TITLE),
        due_and_overdue_invoices:(state, getters) => [...getters.due_invoices, ...getters.overdue_invoices],
        is_loading_invoices: state =>  state.is_loading_invoices,
        invoices_current_billing_cycle: state =>  state.invoices_current_billing_cycle,
        is_downloading_invoice_pdf: state =>  state.is_downloading_invoice_pdf,
        invoices_loading_error: state =>  state.invoices_loading_error,
        INVOICE_STATUS: state =>  state.INVOICE_STATUS,
        INVOICE_BILLING_CYCLES: state =>  state.INVOICE_BILLING_CYCLES,

        invoices_paginated: state => state.invoices_paginated.flat(),
        invoicesPaginated: state => page => state.invoices_paginated.length > --page ? state.invoices_paginated[page] : null,

        current_period_invoices_paginated: (state, getters) => getters.invoices_paginated.filter(invoice => invoice.Status.toUpperCase() == INVOICE_STATUS.OPEN.TITLE),
        previousPeriodInvoicesPaginated: state => page => state.invoices_paginated.length > --page ? state.invoices_paginated[page].filter(invoice => invoice.Status.toUpperCase() != INVOICE_STATUS.OPEN.TITLE) : [],

        invoices_paginated_limit: state => state.invoices_paginated_limit,
        invoices_paginated_total: state => state.invoices_paginated_total,
        invoices_paginated_total_pages: state => Math.ceil(state.invoices_paginated_total / state.invoices_paginated_limit),

        loadingInvoicesPaginated: state => page => state.loading_invoices_paginated.length > --page ? state.loading_invoices_paginated[page] : null,
        isLoadingInvoicesPaginated: (state, getters) => page => Boolean(getters.loadingInvoicesPaginated(page)),
        is_loading_invoices_paginated: state => state.loading_invoices_paginated.findIndex(loading_promise => loading_promise !== null) > -1,

        invoicesLoadingErrorPagination: state => page => state.invoices_loading_error_pagination.length > --page ? state.invoices_loading_error_pagination[page] : false,
    },

    actions: {
        getInvoices({getters, commit, dispatch}, { force = false, is_get_invoices_current_billing_cycle = true } = {}) {
            if (getters.is_loading_invoices || (!force && !getters.is_app_environment_invoices_allowed)) return;

            commit('SET_INVOICES_LOADING_ERROR', false)

            const uuid = getters.current_account_uid

            if (!uuid) return Promise.reject(i18n.t('no_uuid'))

            commit('SET_IS_LOADING_INVOICES', true)

            if (getters.is_app_environment_billing_cycle_allowed && is_get_invoices_current_billing_cycle) {
                dispatch('getInvoicesCurrentBillingCycle')
            }

            return axiosIntents.get(`/v2/customer/${ uuid }/invoices`)
                .then(({ result: invoices }) => {
                    if (!invoices || invoices.length == 0) {
                        commit('DELETE_INVOICES')
                    } else {
                        commit('SET_INVOICES', invoices)
                    }

                    return invoices
                })
                .catch(error => {
                    console.log('getInvoices error', error)

                    commit('SET_INVOICES_LOADING_ERROR', true)
                    commit('DELETE_INVOICES')

                    return Promise.resolve([])
                })
                .finally(()=> {
                    commit('SET_IS_LOADING_INVOICES', false)
                })
        },

        getInvoicesPaginated({getters, commit, dispatch}, { page = 1, force = false, is_get_invoices_current_billing_cycle = true } = {}) {
            const loading_invoices_paginated = getters.loadingInvoicesPaginated(page)

            if (loading_invoices_paginated) {
                return loading_invoices_paginated
            } else if (force || getters.is_app_environment_invoices_allowed) {
                commit('SET_INVOICES_LOADING_ERROR_PAGINATED', { error: false, page })

                const uuid = getters.current_account_uid

                if (uuid) {
                    if (getters.is_app_environment_billing_cycle_allowed
                        && is_get_invoices_current_billing_cycle
                        && !getters.invoices_paginated.length
                    ) {
                        dispatch('getInvoicesCurrentBillingCycle')
                    }

                    if (force || !cashed.invoices_paginated.pages[page] || cashed.invoices_paginated.pages[page].expired()) {
                        const params = {
                            PageNumber: page,
                            PageSize: getters.invoices_paginated_limit,
                        }

                        const loading_promise = axiosIntents.get(`/v3/customer/${ uuid }/invoices`, { params }).then(({data}) => {
                            commit('SET_INVOICES_PAGINATED', { invoices: data.Invoices, page })
                            commit('SET_INVOICES_PAGINATED_TOTAL', data.PageInfo.TotalItemCount)

                            return Promise.resolve(getters.invoicesPaginated(page))
                        }).catch(error => {
                            commit('SET_INVOICES_LOADING_ERROR_PAGINATED', { error, page })

                            return Promise.resolve(getters.invoicesPaginated(page))
                        }).finally(()=> {
                            commit('SET_LOADING_INVOICES_PAGINATED', { loading_promise: null, page } )
                        })

                        commit('SET_LOADING_INVOICES_PAGINATED', { loading_promise, page })

                        return loading_promise
                    } else {
                        return Promise.resolve(getters.invoicesPaginated(page))
                    }
                } else {
                    return Promise.reject(i18n.t('no_uuid'))
                }
            }
        },

        getInvoicesCurrentBillingCycle({getters, commit}) {
            const CustomerUUID = getters.current_account_uid

            if (!CustomerUUID || !getters.is_app_environment_billing_cycle_allowed) {
                commit('SET_INVOICES_CURRENT_BILLING_CYCLE', null)

                return Promise.resolve(true)
            }

            return axiosIntents.get(`/customers/${CustomerUUID}/billing-cycle`).then(({data = {}} = {})=> {
                const { BillingCycle } = data

                if (!BillingCycle || !BillingCycle.Type || !BillingCycle.Value) {
                    commit('SET_INVOICES_CURRENT_BILLING_CYCLE', null)

                    return Promise.resolve(true)
                }

                const invoices_current_billing_cycle = BillingCycle.Type == 'monthly'
                                                        ? INVOICE_BILLING_CYCLES.MONTHLY
                                                        : BillingCycle.Value == 7
                                                            ? INVOICE_BILLING_CYCLES.SEVEN_DAYS
                                                            : INVOICE_BILLING_CYCLES.TWENTY_EIGHT_DAYS

                commit('SET_INVOICES_CURRENT_BILLING_CYCLE', invoices_current_billing_cycle)

                return Promise.resolve(invoices_current_billing_cycle)

            }).catch(error => {
                console.log('getInvoicesCurrentBillingCycle Error', error)

                commit('SET_INVOICES_CURRENT_BILLING_CYCLE', null)

                return Promise.resolve(true)
            })
        },

        setInvoicesCurrentBillingCycle({getters, commit, dispatch}, payload = {}) {
            if (!getters.is_app_environment_billing_cycle_allowed) {
                commit('SET_INVOICES_CURRENT_BILLING_CYCLE', null)

                return Promise.reject(i18n.t('not_allowed'))
            }

            const CustomerUUID = getters.current_account_uid

            if (!CustomerUUID) {
                return Promise.reject(i18n.t('no_uuid'))
            }

            return axiosIntents.post(`/customers/${CustomerUUID}/billing-cycle`, payload).then(()=> {
                const { BillingCycle } = payload

                const invoices_current_billing_cycle = BillingCycle.Type == 'monthly'
                                                        ? INVOICE_BILLING_CYCLES.MONTHLY
                                                        : BillingCycle.Value == 7
                                                            ? INVOICE_BILLING_CYCLES.SEVEN_DAYS
                                                            : INVOICE_BILLING_CYCLES.TWENTY_EIGHT_DAYS

                commit('SET_INVOICES_CURRENT_BILLING_CYCLE', invoices_current_billing_cycle)

                dispatch('getInvoices', { is_get_invoices_current_billing_cycle: false })

                return Promise.resolve(invoices_current_billing_cycle)
            }).catch(error => {
                console.log('setInvoicesCurrentBillingCycle Error', error)

                return Promise.reject(error)
            })
        },

        downloadInvoicePdf({getters, commit}, invoice_number) {
            if (getters.is_downloading_invoice_pdf) return;

            const uuid = getters.current_account_uid

            if (!uuid) return Promise.reject(new Error(i18n.t('no_uuid')))
            if (!invoice_number) return Promise.reject(new Error(i18n.t('no_invoice_number')))
            
            commit('SET_IS_DOWNLOADING_INVOICE_PDF', true)

            return axiosIntents.get(`/customer/${ uuid }/invoice/${invoice_number}/pdf`, {responseType: 'blob'})
                .then(({ result: blob }) => {
                    return downloadBlob(`${invoice_number}.pdf`, blob)
                })
                .catch(error => {
                    console.log('downloadInvoicePdf error', error)

                    return Promise.reject(error)
                })
                .finally(()=> {
                    commit('SET_IS_DOWNLOADING_INVOICE_PDF', false)
                })
        },
    },

    mutations: {
        SET_IS_LOADING_INVOICES(state, is_loading_invoices) {
            state.is_loading_invoices = is_loading_invoices
        },

        SET_IS_DOWNLOADING_INVOICE_PDF(state, is_downloading_invoice_pdf) {
            state.is_downloading_invoice_pdf = is_downloading_invoice_pdf
        },

        SET_INVOICES_LOADING_ERROR(state, invoices_loading_error) {
            state.invoices_loading_error = invoices_loading_error
        },

        SET_INVOICES(state, invoices) {
            state.invoices = invoices
        },

        DELETE_INVOICES(state) {
            state.invoices = []
        },

        SET_INVOICES_CURRENT_BILLING_CYCLE(state, current_billing_cycle) {
            state.invoices_current_billing_cycle = current_billing_cycle
        },

        SET_LOADING_INVOICES_PAGINATED(state, { loading_promise, page }) {
            if (--page < state.loading_invoices_paginated.length) {
                state.loading_invoices_paginated.splice(page, 1, loading_promise)
            } else {
                if (page > state.loading_invoices_paginated.length) {
                    for (let i = state.loading_invoices_paginated.length; i < page; i++) {
                        state.loading_invoices_paginated.push(false)
                    }
                }

                state.loading_invoices_paginated.push(loading_promise)
            }
        },

        SET_INVOICES_LOADING_ERROR_PAGINATED(state, { error, page }) {
            if (--page < state.invoices_loading_error_pagination.length) {
                state.invoices_loading_error_pagination.splice(page, 1, error)
            } else {
                if (page > state.invoices_loading_error_pagination.length) {
                    for (let i = state.invoices_loading_error_pagination.length; i < page; i++) {
                        state.invoices_loading_error_pagination.push(false)
                    }
                }

                state.invoices_loading_error_pagination.push(error)
            }
        },

        SET_INVOICES_PAGINATED(state, { invoices, page }) {
            if (--page < state.invoices_paginated.length) {
                state.invoices_paginated.splice(page, 1, invoices)
            } else {
                if (page > state.invoices_paginated.length) {
                    for (let i = state.invoices_paginated.length; i < page; i++) {
                        state.invoices_paginated.push(Array())
                    }
                }

                state.invoices_paginated.push(invoices)
            }

            if (!cashed.invoices_paginated.pages[++page]) {
                cashed.invoices_paginated.pages[page] = new StoreHash(cashed.invoices_paginated.lifetime)
            }
            cashed.invoices_paginated.pages[page].fix()
        },

        SET_INVOICES_PAGINATED_TOTAL(state, total) {
            state.invoices_paginated_total = total
        },

        DELETE_INVOICES_PAGINATED(state) {
            state.invoices_paginated = Array()
            state.loading_invoices_paginated = Array()
            state.invoices_loading_error_pagination = Array()
            state.invoices_paginated_total = 0

            cashed.invoices_paginated.pages = {}
        },
    },
}
